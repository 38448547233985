<template>
<v-app>

<v-system-bar v-if="isNotProduction"
  height="50"
  color="error"
>
  <label style="color:#fff;text-weight:bold">STAGING!</label>
</v-system-bar>


<v-container fluid fill-height>
  <v-row class="auth-view" style="height:100%" justify="center">
    <v-col class="auth-right" cols="12" sm="12" md="8">
      <v-row justify="center" style="height:100%">
        <v-col cols="12" xs="12" align-self="start"><h4 class="title text-center hidden-md-and-up">THE NETWORK HUB</h4></v-col>
        <v-col cols="12" xs="12" align-self="center">
          <v-row justify="center">
            <v-col cols="12" xs="12" md="6">
              <h2 class="title">Sign in to The Network Hub Dashboard</h2>
              <h4 class="subtitle">Enter your details below</h4>
              <v-form ref="form" @submit.prevent="loginHandler">
                <div class="px-2">{{email}}</div>
                <v-text-field
                  v-model="password"
                  label="Password"
                  placeholder=""
                  outlined
                  required
                  autocomplete="current-password"
                  :error="error"
                  :append-icon="show4 ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="show4 = !show4"
                  :type="show4 ? 'text' : 'password'"
                  :error-messages="error ? 'You have entered an invalid email or password' : ''"
                  :loading="loading"
                ></v-text-field>
                <v-row align="center" justify="space-between" >
                  <v-col class="text-left">
                  <v-btn type="submit" depressed color="primary">SIGN IN</v-btn>
                  <v-btn class="ml-2" type="button" to="/login" depressed color="error">BACK</v-btn>
                  </v-col>
                  <!--
                  <v-col style="text-align:right;">
                    <a href="">Forgot password?</a>
                  </v-col>
                  -->
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-col>
        <v-col align-self="end"></v-col>
      </v-row>
    </v-col>
  </v-row>
</v-container>

</v-app>
</template>
<script>
//import axios from 'axios'
// import {mapActions} from 'vuex'
//import {VENDOR_LOGIN_MUTATION} from '../graphql/mutations'
// import {USER_LOGIN_MUTATION} from '../graphql/mutations'
// import {onLogin} from '../vue-apollo'

export default {
  name: 'Login',

  props: ['email'],

  data:() => ({
    show4: false,
    password: null,
    loading: false,
    error: false,
  }),

  computed: {
    isNotProduction() {
      return process.env.NODE_ENV !== 'production'
    },
  },

  mounted() {
    console.log(this.email)
    if (!this.email) this.$router.push('/login')
  },

  methods: {
    async loginHandler() {
      this.$root.$emit('loading', true)
      const {email, password} = this
      this.$store.dispatch('login', { email, password})
        .then((redirectTo) => {
          this.$router.push(redirectTo)
        })
        .catch((e) => {
          console.log(e)
          this.error = true
          this.errorMessage = e.message
          this.username = ''
          this.password = ''
        })
        .finally(() => {
          this.$root.$emit('loading', false)
        })

    }
  }
}
</script>

<style lang="scss" scoped>
$color-pack: false;

/*@import '~vuetify/src/styles/main.sass';*/

h2 {
  /* Desktop/Heading 2/Bold (32|16) */
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 124%;
  /* or 40px */
}
h3 {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 132%;
}
h4 {
  /* Desktop/Heading 4/Regular (16|8) */
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */
}
h2.title {
  color: #1D3557
}
h4.subtitle {
  color:#323232;
  margin-bottom: 24px;
}
.auth-left {
  // background-image: url(../assets/login.png);
  background-position: top;
  background-size: cover;
  position: relative;
}
.auth-view .auth-left h3, .auth-view .auth-left h2 {
  color:#fff;
  text-align: center;
}
.auth-view .auth-left h3.sub {
  font-weight: 300;
  margin-top: 48px;
}
.auth-view .auth-left h3.sub1 {
  font-size: 18px;
  color: #E6E6E6;
  opacity: 0.9;
}
.container {
  padding-top: 0;
  padding-bottom: 0;
}
</style>